const cards = [
   {
      id: "img01",
      target: "",
      alt: "Balanço",
      title: "Balanço",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Balanço com base",
      title: "Balanço com base",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Balanço e Puff Nápole",
      title: "Balanço e Puff Nápole",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Balanço Jurerê aberto",
      title: "Balanço Jurerê aberto",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Balanço Nápole",
      title: "Balanço Nápole",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Balanço Jurerê",
      title: "Balanço Jurerê",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Balanço Spiral",
      title: "Balanço Spiral",
      description: "",
   },
]

export { cards }